var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_content-media-list m-2" },
    _vm._l(_vm.mediasFormatted, function (media, position) {
      return _c(
        "div",
        {
          key: position,
          class: [
            "_content-media-item",
            _vm.actionOnClick ? "active-border cursor-pointer" : "",
          ],
        },
        [
          _c(
            "div",
            {
              staticClass: "_content-media",
              on: {
                click: function ($event) {
                  return _vm.clickedMedia(media)
                },
              },
            },
            [
              _vm._t("prepend", null, { media: media }),
              _c("div", { staticClass: "_content-media-icon" }, [
                _vm.hasThumbnail(media)
                  ? _c("div", { staticClass: "_content-media-thumbnail" }, [
                      _c("img", {
                        staticClass: "w-full h-full",
                        attrs: { src: _vm.getMediaThumbnailUrl(media) },
                      }),
                    ])
                  : _c(
                      "div",
                      {
                        staticClass:
                          "w-full h-full flex items-center justify-center",
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "h-8 w-8 m-auto",
                          attrs: { icon: "file" },
                        }),
                      ],
                      1
                    ),
              ]),
              _c(
                "div",
                { staticClass: "_content-media-text" },
                [
                  _vm._t(
                    "title",
                    function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "_content-media-title break-words" },
                          [_vm._v(" " + _vm._s(_vm.getMediaTitle(media)) + " ")]
                        ),
                      ]
                    },
                    { media: media }
                  ),
                  _c("div", { staticClass: "_content-media-subtitle" }, [
                    _vm._v(" " + _vm._s(_vm.getMediaSubtitle(media)) + " "),
                  ]),
                ],
                2
              ),
              _c("div", { staticClass: "_content-media-action" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full h-full flex items-center justify-center pr-1 mr-5",
                  },
                  [
                    _c("font-awesome-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Visualizar",
                          expression: "'Visualizar'",
                        },
                      ],
                      staticClass: "h-5 w-5 m-auto hover:text-success mr-5",
                      attrs: { icon: _vm.getMediaActionIcon(media) },
                      on: {
                        click: () =>
                          _vm.actionOnClick
                            ? undefined
                            : _vm.mediaAction(media, position),
                      },
                    }),
                    _vm.showDelete
                      ? _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Excluir",
                              expression: "'Excluir'",
                            },
                          ],
                          staticClass: "h-5 w-5 m-auto hover:text-danger mr-5",
                          attrs: { icon: "times" },
                          on: {
                            click: function ($event) {
                              return _vm.confirmOnDelete(media)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }